export const emptyInstances = {
  categories: {
    id: false,
    title: '',
    color: '',
    image: '',
    hide: false
  },
  chefs: {
    id: false,
    name: '',
    phone: '',
    kitchen: null,
    kitchenRole: {},
    master: false,
    color: '',
    mobile: '',
    country: '',
    kitchen_terminal: null,
    kitchen_role: {},
    image: ''
  },
  extras: {
    title: '',
    price: 0,
    free: true,
    business: '',
    index: null,
    group: ''
  },
  groups: {
    title: '',
    business: '',
    extras_group: [],
    free: true
  },
  hours: {},
  items: {
    id: false,
    hide: false,
    title: '',
    symbol: '',
    description: '',
    image: '',
    price: '',
    kitchen_terminal: null,
    categories: {},
    labels: {},
    allergens: {},
    extras: [],
    regularOptions: {},
    groupOptions: {},
    menus: [],
    visible: true,
    warnings: [],
    price_special: null,
    price_special_expiration: null,
    take_away: false,
    archive: false,
    extras_group: []
  },
  kitchens: {
    id: false,
    title: '',
    color: '',
    items: [],
    main: false
  },
  menus: {
    id: false,
    hide: false,
    title: '',
    color: '',
    from: '',
    to: '',
    items_with_special_price: {},
    ref: ''
  },
  supervisors: {
    id: false,
    name: '',
    phone: '',
    mobile: '',
    country: '',
    email: ''
  },
  tables: {
    id: false,
    title: '',
    seats: '',
    color: '',
    type: 0,
    hide: false,
    pin: ''
  },
  waiters: {
    id: false,
    name: '',
    phone: '',
    color: '',
    tables: [],
    country: '',
    image: ''
  }
}

export default {
  emptyInstances
}
