<template>
  <q-dialog maximized persistent :model-value="show">
    <div class="fit flex flex-center" @click.self="$emit('cancel')">
      <q-card class="dd-confirm-dialog cropper-card text-center q-pa-md rounded-borders">
        <div class="flex absolute justify-end">
          <q-btn flat round dense icon="mdi-close-circle-outline" color="primary" @click.stop="$emit('cancel')"></q-btn>
        </div>
        <div class="text-center q-mb-md">
          <div class="text-center text-primary text-h6 text-weight-medium">
            {{ $t('imageCropper.cropperTitle') }}
          </div>
          <div class="text-center text-primary text-normal text-weight-medium">
            {{ $t('imageCropper.cropperSubTitle') }}
          </div>
        </div>
        <div v-if="!isReady" class="flex flex-center q-pa-lg image-placeholder">
          <q-spinner-dots v-if="!!initialSrc" class="absolute" size="6em" color="primary">
          </q-spinner-dots>
          <q-img :src="demoLogo" />
        </div>
        <cropper ref="cropperRef"
          style="direction: ltr!important;"
          :canvas="{
            height: canvasSide,
            width: canvasSide
          }"
          class="cropper"
          :stencil-props="{
          aspectRatio: 10 / 10,
          class: 'cropper-stencil',
          previewClass: 'cropper-stencil__preview',
          draggingClass: 'cropper-stencil--dragging',
          handlersClasses: {
            default: 'cropper-handler',
            eastNorth: 'cropper-handler--east-north',
            westNorth: 'cropper-handler--west-north',
            eastSouth: 'cropper-handler--east-south',
            westSouth: 'cropper-handler--west-south',
          },
        }" 
        :src="image.src"
        @ready="isReady = true"
        @change="setCanvas"
        />
        <template v-if="!isInitialImage">
          <q-item>
            <q-item-section avatar>
              <!-- <q-icon color="primary" name="mdi-plus" /> -->
              <div class="text-primary text-weight-medium text-normal">
                <!-- Image Quality: -->
                {{ $t('imageCropper.imageQuality') }}
                </div>
            </q-item-section>
            <q-item-section>
              <q-slider v-model="quality" label label-always :min="0.01" :max="1.0" :step="0.01" />
            </q-item-section>
          </q-item>
          <div class="row q-mb-md">
            <div class="col-6">
              <div :class="hasError ? 'text-coolRed' : 'text-primary'" class="text-weight-medium text-normal">
                <span>{{ $t('imageCropper.imageSize') }}</span>
                <span> {{ dataSize !== 0 ? dataSize + ' KB' : $t('imageCropper.calcOnCrop') }}</span>
              </div>
            </div>
            <div class="col-6">
              <div class="text-primary text-weight-medium text-normal">
                <span>{{ $t('imageCropper.maxImageSize') }}</span>
                <span> {{ maxImageSize + ' KB' }}</span>
              </div>
            </div>
          </div>
          <div class="text-center text-primary">
            {{ $t('imageCropper.reduceSizeHint') }}
          </div>
        </template>
        <div class="button-wrapper">
          <q-btn class="buttonGrey" @click="file.click()">
            <input type="file" ref="file" @change="uploadImage($event)" accept="image/*" />
            <!-- Upload Another Image -->
            {{ $t('imageCropper.replaceImageBtn') }}
          </q-btn>
          <q-btn class="buttonGreen" @click="cropImage()">
            <!-- Crop and Save -->
            {{ $t('imageCropper.cropImageBtn') }}
            </q-btn>
        </div>
      </q-card>
    </div>
  </q-dialog>
</template>

<script>
import { defineComponent, ref, reactive, onUnmounted, watch, computed } from "vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default defineComponent({
  name: 'ImageCropper',
  components: {
    Cropper,
  },
  props: {
    show: Boolean,
    initialSrc: String,
    src: String,
    maxImageSize: {
      type: Number,
      default: 300 // KB
    },
    canvasSide: {
      type: Number,
      default: 800
    }
  },
  emits: ['confirm', 'cancel'],
  setup(props, { emit }) {
    const image = reactive({
      src: props.initialSrc,
      type: "image/jpg",
    });

    const canvasRef = ref(null)

    function setCanvas({ canvas }) {
      canvasRef.value = canvas
    }

    watch(() => props.src, val => {
      image.src = val
    })

    watch(() => props.show, val => {
      if (!val) {
        dataSize.value = 0
        image.src = props.initialSrc
        isReady.value = false
        hasError.value = false
      }
    })

    const demoLogo = require('src/assets/img/demo_image_512.png')

    const cropperRef = ref(null)
    const isReady = ref(false)

    const file = ref()
    const dataSize = ref(0)
    const quality = ref(0.9)
    const hasError = ref(false)

    const isInitialImage = computed(() => props.initialSrc === image.src)

    const cropImage = () => {
      const data_url = canvasRef.value.toDataURL(
        image.type, quality.value
      )

      // Alt size method
      // canvas.toBlob(function(blob){
      //     alert(blob.size);
      // });

      dataSize.value = Math.round((data_url.length - 22) * 3 / 4 / 1024) // size in KB

      if (dataSize.value <= props.maxImageSize || isInitialImage.value || quality.value <= 0.1) {
        emit('confirm', data_url)
      } else {
        hasError.value = true
      }
    };

    const uploadImage = (event) => {
      /// Reference to the DOM input element
      const { files } = event.target
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (image.src) {
          URL.revokeObjectURL(image.src)
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0])

        // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
        image.src = blob
        image.type = files[0].type

        dataSize.value = 0
        hasError.value = false
      }
    }

    onUnmounted(() => {
      if (image.src) {
        URL.revokeObjectURL(image.src)
      }
      dataSize.value = 0
    })

    return {
      image,
      cropperRef,
      isReady,
      file,
      uploadImage,
      cropImage,
      dataSize,
      quality,
      hasError,
      isInitialImage,
      demoLogo,
      setCanvas
    }
  },
})
</script>

<style lang="scss">
.vue-advanced-cropper {
  direction: ltr!important;
}
.cropper {
  max-height: 450px;
  max-width: 450px;
  direction: ltr !important;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}

.buttonGreen {
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  width: 100%;
  background: #73be00;
  cursor: pointer;
  transition: background 0.5s;
  border: none;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  &:hover {
    background: #2F2F2F;
  }

  input {
    display: none;
  }
}

.buttonGrey {
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  width: 100%;
  background: #66788a;
  cursor: pointer;
  transition: background 0.5s;
  border: none;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  &:hover {
    background: #2F2F2F;
  }

  input {
    display: none;
  }
}

.cropper-stencil {
  &__preview {

    &:after,
    &:before {
      content: "";
      opacity: 0;
      transition: opacity 0.25s;
      position: absolute;
      pointer-events: none;
      z-index: 1;
    }

    &:after {
      border-left: solid 1px white;
      border-right: solid 1px white;
      width: 33%;
      height: 100%;
      transform: translateX(-50%);
      left: 50%;
      top: 0;
    }

    &:before {
      border-top: solid 1px white;
      border-bottom: solid 1px white;
      height: 33%;
      width: 100%;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
    }
  }

  &--dragging {
    .cropper-stencil__preview {

      &:after,
      &:before {
        opacity: 0.4;
      }
    }
  }
}

.cropper-line {
  border-color: rgba(white, 0.8);
}

.cropper-handler {
  display: block;
  opacity: 0.7;
  position: relative;
  flex-shrink: 0;
  transition: opacity 0.5s;
  border: none;
  background: white;
  top: auto;
  left: auto;
  height: 4px;
  width: 4px;
  $length: 16px;

  &--west-north,
  &--east-south,
  &--west-south,
  &--east-north {
    display: block;
    height: $length;
    width: $length;
    background: none;
  }

  &--west-north {
    border-left: solid 2px white;
    border-top: solid 2px white;
    top: $length / 2 - 1px;
    left: $length / 2 - 1px;
  }

  &--east-south {
    border-right: solid 2px white;
    border-bottom: solid 2px white;
    top: -$length / 2 + 1px;
    left: -$length / 2 + 1px;
  }

  &--west-south {
    border-left: solid 2px white;
    border-bottom: solid 2px white;
    top: -$length / 2 + 1px;
    left: $length / 2 - 1px;
  }

  &--east-north {
    border-right: solid 2px white;
    border-top: solid 2px white;
    top: $length / 2 - 1px;
    left: -$length / 2 + 1px;
  }

  &--hover {
    opacity: 1;
  }
}

[dir='rtl'] .cropper-handler {
  direction: ltr!important;
  display: block;
  $length2: 16px;

  &--west-north,
  &--east-south,
  &--west-south,
  &--east-north {
    display: block;
    height: $length2;
    width: $length2;
    background: none;
    transform: rotateY(180deg)!important;
  }

  &--west-north {
    left: -$length2 - 5px!important;
  }

  &--east-south {
    left: $length2 + 5px;
  }

  &--west-south {
    left: -$length2 - 5px!important;
  }

  &--east-north {
    left: $length2 + 5px;
    cursor: ne-resize;
  }
}


[dir="rtl"] .vue-handler-wrapper {
  transform: translate(-50%, -50%)!important;
}
.image-placeholder {
  width: 512px;
  height: 512px;
  border: #cecece solid 1px!important;
  background: aliceblue;
}
.cropper-card {
  min-width: 512px;
  min-height: 512px;
}
</style>
