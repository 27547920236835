import timezones from 'src/utils/timezones.json'
import { Notify } from 'quasar';

function isObjectsEqual(object1, object2) {
  const props1 = object1 ? Object.getOwnPropertyNames(object1) : []
  const props2 = object2 ? Object.getOwnPropertyNames(object2) : []

  if (props1.length !== props2.length) {
    // console.log('not equal: not same lenght', props1, props2)
    return false;
  }

  for (let i = 0; i < props1.length; i += 1) {
    const prop = props1[i];
    const bothAreObjects = typeof(object1[prop]) === 'object' && typeof(object2[prop]) === 'object';

    if ((!bothAreObjects && (object1[prop] !== object2[prop]))
    || (bothAreObjects && !isObjectsEqual(object1[prop], object2[prop]))) {
      // console.log('not equal', bothAreObjects, object1[prop] !== object2[prop], object1[prop], object2[prop])
      return false;
    }
  }

  return true;
}

async function getDataUrl (url) {
  const blob = await fetch(url).then(r => r.blob());
  const dataUrl = await new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
  return dataUrl
}

function getTimezoneByText(text) {
	for (let i = 0; i < timezones.length; i++) {
		if (timezones[i].text === text) {
			return timezones[i];
		}
	}
	return null;
}

function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    Notify.create({
      color: 'darkGreen',
      icon: 'mdi-check',
      message: 'The URL link has been copied***'
    })
  }, function(err) {
    Notify.create({
      color: 'orange',
      icon: 'mdi-alert',
      message: 'Async: Could not copy text: ', err
    })
  });
}

async function shareData(data) {
  await navigator.share(data);
}

export {
  getTimezoneByText,
  isObjectsEqual,
  getDataUrl,
  copyTextToClipboard,
  shareData
}
